.rotate-right {
   -webkit-animation: rotate-right 10s linear infinite both;
   animation: rotate-right 10s linear infinite both;
}

@-webkit-keyframes rotate-right {
   0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
   }
   100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
   }
}
@keyframes rotate-right {
   0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
   }
   100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
   }
}

.rotate-left {
   -webkit-animation: rotate-left 10s linear infinite reverse both;
   animation: rotate-left 10s linear infinite reverse both;
}

@-webkit-keyframes rotate-left {
   0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
   }
   100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
   }
}
@keyframes rotate-left {
   0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
   }
   100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
   }
}
